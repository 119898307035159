import React from 'react';
import ReactDOM from 'react-dom';
import Routing from './route';
import { Provider } from 'react-redux';
import store from './store';
import * as serviceWorker from './serviceWorker';

const app = (
  <Provider store={store}>
          <Routing/>
  </Provider>
);

const startApp = () => {
  ReactDOM.render(app, document.getElementById('root'));
}
if(!window.cordova) {
  startApp()
} else {
  document.addEventListener('deviceready', startApp, false)
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
