import { ACTIONS } from '../utils/Actiontypes';
import { invokeGetServices } from '../utils/Webservices';
import { apiDetails } from '../utils/config';

// Home page action
export const getAllPlaylists = () => {
    return ((dispatch) => {
        const { getPlaylistsEndpoint } = apiDetails;
        invokeGetServices(getPlaylistsEndpoint).then((response) => {
            const { GET_ALL_PLAYLIST_SUCCESS } = ACTIONS;
            dispatch({
                type: GET_ALL_PLAYLIST_SUCCESS,
                payloads: response.data
            });
        }).catch((error) => {
            const { GET_ALL_PLAYLIST_FAILED } = ACTIONS;
            dispatch({
                type: GET_ALL_PLAYLIST_FAILED,
                payloads: error
            });
        })
    })
}
export const searchVideo = (text) => {
    return ((dispatch) => {
        const { SEARCH_DATA } = ACTIONS;
        dispatch({
            type: SEARCH_DATA,
            payloads: text
        });
    })
}

// Details page action
export const selectVideoList = (...arg) => {
    return ((dispatch) => {
        console.log("chnages",arg[0]);
        const { getPlaylistsEndpoint } = apiDetails;
        invokeGetServices(getPlaylistsEndpoint).then((response) => {
            let obj = {};
            obj.playlistId = response.data.items[arg[0]].id;
            obj.vList = response.data.items[arg[0]];
            obj.vPosition = arg[1];
            const { SELECT_VIDEOLIST } = ACTIONS;
            dispatch({
                type: SELECT_VIDEOLIST,
                payloads: obj
            });
            const { GET_ALL_PLAYLIST_SUCCESS } = ACTIONS;
            dispatch({
                type: GET_ALL_PLAYLIST_SUCCESS,
                payloads: response.data
            });

        }).catch((error) => {
            const { GET_ALL_PLAYLIST_FAILED } = ACTIONS;
            dispatch({
                type: GET_ALL_PLAYLIST_FAILED,
                payloads: error
            });
        })
       
    })
}

//AboutUs page action
export const getChannelDetails = () => {
    return ((dispatch) => {
        const { getChannelDetailsEndpoint } = apiDetails;
        invokeGetServices(getChannelDetailsEndpoint).then((response) => {
            const { GET_CHANNEL_DETAILS_SUCCESS } = ACTIONS;
            dispatch({
                type: GET_CHANNEL_DETAILS_SUCCESS,
                payloads: response.data
            });
        }).catch((error) => {
            const { GET_CHANNEL_DETAILS_FAILURE } = ACTIONS;
            dispatch({
                type: GET_CHANNEL_DETAILS_FAILURE,
                payloads: error
            });
        })
    })
}