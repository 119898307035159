import React  from 'react';
import {withRouter} from 'react-router-dom';
import { img } from '../../utils/config';
import '../../styles/about.css';
import '../../styles/about_responsive.css';
// import {getChannelDetails } from '../../webservices';

class Aboutus extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			isRenderer: false,
			ChannelDetails: '',
        }
    }
    UNSAFE_componentWillMount(){
        this.props.getChannelDetails()
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0; 
    }
    
    render() {
        const {ChannelDetails}=this.props.aboutUsState;
        return (
            <div className="super_container">
                <div className="artist">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="~artist_content">
                                    <div className="section_title_container">
                                        <div className="section_subtitle">Events</div>
                                        <div className="section_title"><h1>{ChannelDetails&&ChannelDetails.snippet.title}</h1></div>
                                    </div>
                                    <div className="artist_text">
                                        <p>{ChannelDetails&&ChannelDetails.snippet.description}</p>
                                    </div>
                                    <div className="mt-5 ~artist_sig"><img width="100%" src={img.aboutus.ksm} alt=""/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="milestones">
                    <div className="milestones_container">
                        <div className="parallax_background parallax-window" data-parallax="scroll"  data-speed="0.8"></div>
                        <div className="container">
                            <div className="row milestones_row">
                                <div className="col-xl-3 col-md-6 milestone_col">
                                    <div className="milestone d-flex flex-row align-items-center justify-content-start">
                                        <div className="milestone_icon"><img src={img.aboutus.milst} alt="https://www.flaticon.com/authors/smashicons"/></div>
                                        <div className="milestone_content">
                                            {/* <div className="milestone_text">Video Count</div> */}
                                            <div className="milestone_counter" data-end-value="298" data-sign-after="k">{ChannelDetails&&ChannelDetails.statistics.videoCount}</div>
                                            <div className="milestone_text">Video Count</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 milestone_col">
                                    <div className="milestone d-flex flex-row align-items-center justify-content-start">
                                        <div className="milestone_icon"><img src={img.aboutus.milst1} alt="https://www.flaticon.com/authors/smashicons"/></div>
                                        <div className="milestone_content">
                                            <div className="milestone_counter" data-end-value="183">{ChannelDetails&&ChannelDetails.statistics.commentCount}</div>
                                            <div className="milestone_text">Comment Count</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 milestone_col">
                                    <div className="milestone d-flex flex-row align-items-center justify-content-start">
                                        <div className="milestone_icon"><img src={img.aboutus.milst2} alt="https://www.flaticon.com/authors/smashicons"/></div>
                                        <div className="milestone_content">
                                            <div className="milestone_counter" data-end-value="37">{ChannelDetails&&ChannelDetails.statistics.subscriberCount}</div>
                                            <div className="milestone_text">Subscriber Count</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 milestone_col">
                                    <div className="milestone d-flex flex-row align-items-center justify-content-start">
                                        <div className="milestone_icon"><img src={img.aboutus.milst3} alt="https://www.flaticon.com/authors/smashicons"/></div>
                                        <div className="milestone_content">
                                            <div className="milestone_counter" data-end-value="14">{ChannelDetails&&ChannelDetails.statistics.viewCount}</div>
                                            <div className="milestone_text">View Count</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(Aboutus)
