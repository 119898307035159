import { ACTIONS } from '../utils/Actiontypes';
import { log } from 'util';
let initialSate = {
    playlists : '',
    searchLists : '',
    isSuccess: false,
}
const { GET_ALL_PLAYLIST_SUCCESS,SEARCH_DATA, GET_ALL_PLAYLIST_FAILED} = ACTIONS;

export const allplaylistState = (state = initialSate, action) => {
    const { type, payloads} = action;
    switch(type) {
        case GET_ALL_PLAYLIST_SUCCESS:
                payloads.items.forEach(e => {
                    e.show = true;
                    e.items.forEach(f => {
                        f.show = true;
                    })
                })
            return {...state, playlists: payloads, searchLists : payloads, isSuccess: true}
        case GET_ALL_PLAYLIST_FAILED:
            return {...state, playlists: '', searchLists: '',isSuccess: false}
        case SEARCH_DATA:
                let givenText = payloads.toUpperCase();
                let  data = state.searchLists;
                data.items.forEach(element => {
                    let filterItem = element.items.filter(videoList => {
                        let listVideoTitle = videoList.snippet.title ? videoList.snippet.title.toUpperCase() : ''.toUpperCase();
                        return listVideoTitle.indexOf(givenText) !== -1;
                    });
                    if (filterItem.length > 0) {
                        element.items.forEach(e => {
                            let filterItems = filterItem.filter(searchItem => searchItem.id === e.id);
                            if (filterItems.length > 0) {
                                e.show = true;
                            } else {
                                e.show = false;
                            }
                        })
                        element.show = true;
                    } else {
                        element.show = false;
                    }
                });
            return{...state, playlists: data,}
        default:
            return state;
    }
}