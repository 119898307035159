import { ACTIONS } from '../utils/Actiontypes';
let initialSate = {
    videolists : '',
}
const { SELECT_VIDEOLIST } = ACTIONS;

export const videolistState = (state = initialSate, action) => {
    const { type, payloads} = action;
    switch(type) {
        case SELECT_VIDEOLIST:  
            return {...state, videolists: payloads}
        default:
            return state;
    }
}