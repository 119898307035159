
import { connect } from 'react-redux';
import Details from '../component/Details/details';
import { bindActionCreators } from 'redux';
import {selectVideoList} from '../actions/actions';
const mapStateToProps = (state) => {
    const {videolistState} = state;
    return {
        videolistState
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        selectVideoList
    }, dispatch);
}

const AppContainer = connect(
    mapStateToProps,mapDispatchToProps
)(Details);

export default AppContainer;