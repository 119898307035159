
import { connect } from 'react-redux';
import AboutUS from '../component/AboutUS/about';
import { bindActionCreators } from 'redux';
import {getChannelDetails} from '../actions/actions';

const mapStateToProps = (state) => {
    const {aboutUsState} = state;
    return {
        aboutUsState
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getChannelDetails
    }, dispatch);
}

const AppContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AboutUS);

export default AppContainer;