import React from 'react';
import {Link,withRouter} from 'react-router-dom';
import $ from 'jquery';

const MenuComponent = (props) =>{
  const handleMenu = () => {
      $('.menu').removeClass('active');    
  }
    return <div className="menu" onClick={()=> handleMenu()} >
    <div>
      <div className="menu_overlay"></div>
      <div className="menu_container d-flex flex-column align-items-start justify-content-center">
        {/* <div className="menu_log_reg">
          <ul className="d-flex flex-row align-items-start justify-content-start">
            <li><a href="#">Login</a></li>
            <li><a href="#">Register</a></li>
          </ul>
        </div> */}
        <nav className="menu_nav">
          <ul className="d-flex flex-column align-items-start justify-content-start">
            <li><Link to='/'>Home</Link></li>
            <li><Link to='/about'>About us</Link></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
}
export default withRouter(MenuComponent)
