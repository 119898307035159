import { ACTIONS } from '../utils/Actiontypes';
let initialSate = {
    ChannelDetails : '',
}
const { GET_CHANNEL_DETAILS_SUCCESS } = ACTIONS;

export const aboutUsState = (state = initialSate, action) => {
    const { type, payloads} = action;
    switch(type) {
        case GET_CHANNEL_DETAILS_SUCCESS:  
            return {...state, ChannelDetails: payloads.items[0]}
        default:
            return state;
    }
}