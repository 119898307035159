import React from 'react';
import Header from './Header/header';
import Menu from './Menu/menu';
import Footer from './Footer/footer';
import '../styles/bootstrap-4.1.2/bootstrap.min.css';

class Layout extends React.Component {
    render() {
        return (
            <div>
                <Header />
                <Menu/>
                {this.props.children}
                <Footer />
            </div>
        )
    }
}
export default Layout