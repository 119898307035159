import { combineReducers } from 'redux';
import {allplaylistState} from '../reducers/Home';
import {videolistState} from '../reducers/Details';
import {aboutUsState} from '../reducers/Abouts';
const rootReducer = combineReducers({
    allplaylistState,
    videolistState,
    aboutUsState
});

export default rootReducer;