import React from 'react';
import { Link,withRouter} from 'react-router-dom';
import { img } from '../../utils/config';

const FooterComponent = () =>{
return <footer className="footer">
		<div className="footer_container d-flex flex-xl-row flex-column align-items-start justify-content-start">
			{/* <div className="newsletter_container">
				<div className="newsletter_title"><h2>Subscribe to our newsletter</h2></div>
				<form action="#" id="newsletter_form" className="newsletter_form">
					<input type="email" className="newsletter_input" placeholder="Your E-mail" required="required"/>
					<button className="newsletter_button">Subscribe</button>
				</form>
			</div> */}
			<div className="footer_lists d-flex flex-sm-row  flex-column align-items-start justify-content-start ml-xl-auto">
				<div className="footer_list">
					<div className="footer_list_title">Useful Links</div>
					<ul>
						<li><Link to='/'>Home</Link></li>
						<li><Link to='/about'>About us</Link></li>
						{/* <li><a href="#">Testimonials</a></li>
						<li><a href="#">Music</a></li>
						<li><a href="blog.html">News</a></li> */}
					</ul>
				</div>
				{/* <div className="footer_list">
					<div className="footer_list_title">Mixtape</div>
					<ul>
						<li><a href="#">Music</a></li>
						<li><a href="#">PR & Marketing</a></li>
						<li><a href="#">Contact</a></li>
						<li><a href="#">About us</a></li>
						<li><a href="#">Testimonials</a></li>
					</ul>
				</div>
				<div className="footer_list">
					<div className="footer_list_title">Connect</div>
					<ul>
						<li><a href="#">Returns</a></li>
						<li><a href="#">Shippings</a></li>
						<li><a href="#">Jobs</a></li>
						<li><a href="#">Social Media</a></li>
						<li><a href="#">Soundcloud</a></li>
					</ul>
				</div> */}

			</div>
		</div>
		<div className="socialLinks">
		{/* <div className="">Links :</div> */}
			<div className="groupLink1">
			<span className="footer_list_title1">Links :</span>
			<img className="groupLink2" width="40" src={img.logo} alt="logo" />
			<img className="groupLink2" width="30" src={img.fb} alt="logo" onClick={()=>window.open("https://www.facebook.com/doopaadoo/")} />
			<img className="groupLink3" width="30" src={img.twitter} alt="logo" onClick={()=>window.open("https://twitter.com/doopaadoo")} />
			</div>
	       </div>
			<div className="copyright_bar">
				<span>Copyright &copy;<script>document.write(new Date().getFullYear());</script> Doopaadoo. All rights reserved 
			</span>
			</div>
			
	</footer>
}
export default withRouter(FooterComponent)