import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { img } from '../../utils/config';
import $ from 'jquery';
import {searchVideo } from '../../actions/actions';
import store from '../../store';

function HeaderComponent  (props) {
  const header = {
    about: '',
    home: ''
  }
  if (props.location.pathname === '/about') {
    header.about = 'active'
    header.home = ''
  } else {
    header.home = 'active'
    header.about = ''
  }
  // let val = true;
  const handleMenu = () => {
    $('.menu').addClass('active');
  }
  
  const searchVideos = (e) => {
    if(props.location.pathname !== '/'){
      props.history.push("/");
    }
    let text = e.target.value;
    store.dispatch(searchVideo(text))
  }
console.log("chnafe",props)
  return <header className="header">
    <div className="header_content d-flex flex-row align-items-center justify-content-center">
      {props.location.pathname === '/' ? <div className="logo" onClick={() => { props.history.push("/") }}>
        <img width="75" src={img.logo} alt="logo" />
      </div> : <div className="logo" onClick={() => { props.history.goBack() }}>
        <img width="75" src={img.leftArrow} alt="back" />
      </div>}
      {/* <div className="log_reg">
              <ul className="d-flex flex-row align-items-start justify-content-start">
                <li><a href="#">Login</a></li>
                <li><a href="#">Register</a></li>
              </ul>
            </div> */}
      
      <div className="log_reg SearchArea">
        <div className="input-group">
          <input type="text" className="form-control" onChange={(e) => searchVideos(e)} placeholder="Search" />
          <div className="input-group-append">
            <button className="btn btn-secondary" type="button">
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <nav className="main_nav">
        <ul className="d-flex flex-row align-items-start justify-content-start">
          <li className={header.home}><Link to='/'>Home</Link></li>
          <li className={header.about}><Link to='/about'>About us</Link></li>
          {/* <li><a href="music.html">Music</a></li>
                <li><a href="blog.html">News</a></li> */}
          {/* <li><a href="contact.html">Contact</a></li> */}
        </ul>
      </nav>
      
      <div className="hamburger ml-auto" >
        <div onClick={() => handleMenu()} className="d-flex flex-column align-items-end justify-content-between">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </header>
}


export default withRouter(HeaderComponent)