import React, {Component} from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import '../../styles/carousel.css';
import {  withRouter,Link } from 'react-router-dom';

class VideoLists extends Component {
  
  // selectVideo = (...arg) =>{
  //   this.props.history.push("/details")
	// 	this.props.selectVideoList(arg)
	// }
    render() {
        const {videolist,playlistIndex} = this.props;
      return(
        <>
            {videolist ?<Carousel
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{

              item6: {
                breakpoint: {
                  max: 3000,
                  min: 1281
                },
                items: 6,
                partialVisibilityGutter: 10
              },



              item5: {
                breakpoint: {
                  max: 1280,
                  min: 1025
                },
                items: 5,
                partialVisibilityGutter: 10
              },

              item4: {
                breakpoint: {
                  max: 1024,
                  min: 769
                },
                items: 4,
                partialVisibilityGutter: 10
              },



              item3: {
                breakpoint: {
                  max: 768,
                  min: 561
                },
                items: 3,
                partialVisibilityGutter: 10
              },


              item2: {
                breakpoint: {
                  max: 560,
                  min: 361
                },
                items: 2,
                partialVisibilityGutter: 10
              },

              item1: {
                breakpoint: {
                  max: 360,
                  min: 0
                },
                items: 1,
                partialVisibilityGutter: 10
              },




            }}
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
            >
            {videolist.filter(e => e.show).map((vList, ci) => 
            <Link to={{ pathname: `/details/${playlistIndex}/${vList.snippet.position}` }} key={ci}>
              <div key={ci} className="margin" >
                  <img src={vList.snippet.thumbnails ? vList.snippet.thumbnails.medium.url : null} alt="video" />
                  <div className="vTitle">
                  <span style={{ color: 'white' }}>{vList.snippet.title}</span>
                  </div>  
                  <div className="vSubTitle">
                  {/* <span className="Vviews" style={{ color: 'white' }}>1.2K views</span>
                  <span className="" style={{ color: 'white' }} >1 month ago</span> */}
                  </div>
              </div>
              </Link>
        )}
        </Carousel> : null}
        </>
      );
    }
  }
export default withRouter(VideoLists)
