// import {configApp} from '../utils/Config';
import axios from 'axios';

export const getChannelDetails = async () => {
    const response = await axios.get(`http://test-gordon-bucket.s3-eu-west-1.amazonaws.com/JSON.json/ChannelDetail.json`);
    return response
}

export const getAllPlaylist = async () => {
    const response = await axios.get(`http://test-gordon-bucket.s3-eu-west-1.amazonaws.com/JSON.json/DPDPlaylist.json`);
    return response
}

export const invokeGetServices = async (url) => {
    const response = await axios.get(url);
    return response
}
