import React  from 'react';
import { withRouter} from 'react-router-dom';
import data from '../../json/DPDPlaylist.json';
import $ from 'jquery';

class Details extends React.Component {
    constructor(props){
        super(props)
        this.state ={
            videoid: props.match.params.videoid,
            playlistID: props.match.params.id
        }
    }
    handleClick = (id) =>{
        this.setState({videoid: id});
        this.scrollTop()
    }
    scrollTop = () => {
        $('html,body').animate({ scrollTop: 0 }, 400);
        return false; 
    }
    UNSAFE_componentWillMount(){       
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0; 
    }
    componentDidMount(){
        const {id,videoid} = this.props.match.params;
        this.props.selectVideoList(id,videoid)

        if((id || videoid) ){
            this.props.selectVideoList(id,videoid)
        }
    }
    render() {
        const {vList,playlistId} = this.props.videolistState.videolists;
        const {videoid} = this.state;
        return (
            <>
           {vList ?  <div className="super_container">
                <div className="Youtube_video_section detailpage Vborder">
                    <div className="vsec">
                    <div className="row">
                    <div className="col-12 col-md-7 col-lg-8">
                        <div className="embed-responsive embed-responsive-16by9">
                        {/* https://www.youtube.com/embed/SbQc_JLUH7k?autoplay=1&loop=1&start=5&end=8&playlist=96kI8Mp1uOU */}
                            <iframe title="doopaadoo" className="embed-responsive-item1" src={`https://www.youtube.com/embed/watch?list=${playlistId}&index=${videoid}&autoplay=1&enablejsapi=1`} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" frameBorder="0" allowFullScreen></iframe>
                            {/* https://www.youtube.com/watch?v=rznIXkw5zn0&list=PLSAtqkpPO3U-WGv7vAMCyfUYbWjS0BozX */}
                            {/* {<iframe className="embed-responsive-item" src={`https://www.youtube.com/embed/watch?${this.state.videoid}&list=${this.state.playlist.id}`} frameBorder="0" allowFullScreen></iframe>} */}
                        </div>
                        <div className="video_title pt-2 pb-2">
                            <span style={{color:'white'}}>{vList.snippet.title}</span>
                        </div>
                    </div>
                    <div className="col-12 col-md-5 col-lg-4">
                    { vList ? vList.items.map((res)=>
                            <div className="row mb-3" style={{cursor: 'pointer'}} onClick={()=>this.handleClick(res.snippet.position)}>
                                <div className="col-5 pr-0">
                                        <img className="img-fluid z-depth-1" 
                                        src={res.snippet.thumbnails ? res.snippet.thumbnails.medium.url : null} 
                                        alt="video"
                                        data-toggle="modal" 
                                        data-target="#modal1"/>
                                </div>
                                <div className="col-7">	
                                    <div className="vTitle">
                                    <span style={{color:'white'}}>{res.snippet.title}</span>
                                    </div>
                                    <div className="vSubTitle">
                                        {/* <span style={{color:'white'}} className="Vviews">1.2K views</span>
                                        <span style={{color:'white'}} className="">1 month ago</span> */}
                                    </div>
                                </div>
                            </div>
                    ) : null}
                    </div>
                </div>
                </div>
	        </div>
            </div> : null}
            </>
        )
    }
}
export default withRouter(Details)
