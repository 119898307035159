
// Google API KEY
// ________________
// API KEY: AIzaSyAO0xwroUQypqQyPpmsKACcv-q4O3Km0sk

// Created A Channel
// ________________
// ChannelID: UCqHYjDvXT7ikxWBnePITPEQ
// Userid: qHYjDvXT7ikxWBnePITPEQ

export const apiDetails = {
    APIKEY: "AIzaSyAO0xwroUQypqQyPpmsKACcv-q4O3Km0sk",
    ChannelID: "UCvZ8JmdizfF2MxawbP8lwdQ",
    Userid: "qHYjDvXT7ikxWBnePITPEQ",
    getPlaylistsEndpoint : `https://d25vw6810tmb41.cloudfront.net/JSON.json/DPDPlaylist.json`,
    getChannelDetailsEndpoint : `https://d25vw6810tmb41.cloudfront.net/JSON.json/ChannelDetail.json`
}

export const img ={
    logo: require('../images/logo.png'),
    leftArrow: require('../images/LeftArrow.png'),
    fb: require('../images/fb.png'),
    twitter: require('../images/twitter.jpg'),
    imgUrl: require('../images/index.jpg'), 
    imgFeature : require('../images/featured.png'),
    imgFeatureAlb : require('../images/featured_album.jpg'), 
    imgShow : require('../images/shows.jpg'),
    imgArtist : require('../images/artist.png'),
    imgSig : require('../images/sig.png'),
    imgExtra : require('../images/extra.jpg'),
    aboutus :{
       imgDist : require('../images/disc_1.jpg'),
       imgDist1 : require('../images/disc_2.jpg'),
       imgDist2 : require('../images/disc_3.jpg'),
       imgDist3 : require('../images/disc_4.jpg'),
       imgDist4 : require('../images/disc_5.jpg'),
       imgDist5 : require('../images/disc_6.jpg'),
       imgArt:  require('../images/artist_2.jpg'),
       mile: require('../images/milestones.jpg'),
       milst : require('../images/icon_1.svg'),
       milst1 : require('../images/icon_2.svg'),
       milst2 : require('../images/icon_3.svg'),
       milst3 : require('../images/icon_4.svg'),
       ksm : require('../images/ksm.jpg')

    }
}
