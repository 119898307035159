import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducers from './reducers/Reducerindex';
import { createLogger } from 'redux-logger';
const loggerMiddleware = createLogger()
/**
 * Creates a store with given reducers
 */

const store =  createStore (
  reducers,
  applyMiddleware(
    thunkMiddleware,
    loggerMiddleware
  )
);

export default store;
