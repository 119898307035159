import React from 'react';
// import App from './App';
import Home from './containers/Home';
import Details from './containers/Details';
import About from './containers/Abouts';
import { Route, HashRouter as Router, Switch } from 'react-router-dom';
import Layout from './component/Layout';

export default class Routing extends React.Component {
    render() {
        return (
            <Router>
                <Layout>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/details/:id?/:videoid?" component={Details} />
                        <Route exact path="/about" component={About} />
                        <Route path='*' exact={true} component={Home} />
                    </Switch>
                </Layout>
            </Router>
        )
    }
}
