
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Home from '../component/Home/home';
import {getAllPlaylists,searchVideo,selectVideoList} from '../actions/actions';
const mapStateToProps = (state) => {
    const {allplaylistState} = state;
    return {
        allplaylistState
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getAllPlaylists,searchVideo,selectVideoList
    }, dispatch);
}

const AppContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);

export default AppContainer;