import React, { Component } from 'react';
import {withRouter } from 'react-router-dom';
import '../../styles/mainstyles.css';
import { img } from '../../utils/config';
import VideoLists from '../Home/carousel';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

class Home extends Component {
	constructor() {
		super();
		this.state = {
			isRenderer: false,
		}
	}
	componentWillMount(){
		this.props.getAllPlaylists();
		document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0; 
	}

	// searchVideos = (e) => {
	// 	let text = e.target.value;
	// 	this.props.searchVideo(text)
	// }
	
	render() {
        const { playlists } = this.props.allplaylistState;
        console.log("data ",playlists)
		return (
			<div className="super_container">
				<div className="home">
					<div className="home_slider_container">
							<Carousel
								additionalTransfrom={0}
								arrows={false}
								autoPlaySpeed={3000}
								centerMode={false}
								className=""
								containerClass="container-with-dots"
								dotListClass=""
								draggable
								focusOnSelect={false}
								infinite
								itemClass=""
								keyBoardControl
								minimumTouchDrag={80}
								renderButtonGroupOutside={false}
								renderDotsOutside={false}
								responsive={{
									desktop: {
										breakpoint: {
											max: 3000,
											min: 0
										},
										items: 1,
										partialVisibilityGutter: 10
									},
								}}
								showDots={true}
								sliderClass=""
								slidesToSlide={1}
								swipeable
							>
								<div className="themeimage">
									{/* <img align="center" src={require('../../images/index.jpg')}/> */}
									<div className="home_content text-center">
										<div className="home_subtitle">Independent &nbsp; Movie Songs</div>
										<div className="home_title"><h1>Music is all around</h1></div>
									</div>
								</div>
							</Carousel>
					</div>
				</div>
				<div> {playlists ? playlists.items.filter(e => e.show).map((list, pi) =>
				<div key={pi} className="Youtube_video_section">
				<div className="Vborder">
					<div className="video_title pt-3 pb-3">
						<span style={{ color: 'white' }}>{list.snippet.title}</span>
					</div>
					<VideoLists selectVideoList={this.props.selectVideoList} playlistIndex={pi} videolist={list.items} />
				</div>
			</div> ) : null}
				</div>
			</div>
		);
	}
}

export default withRouter(Home)